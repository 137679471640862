/* eslint-disable react-hooks/exhaustive-deps */
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Stack, Tooltip, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGridPro, deDE, GridActionsCellItem } from '@mui/x-data-grid-pro';
import download from 'downloadjs';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useEffect, useState } from 'react';
import 'react-image-lightbox/style.css';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { HOST_API } from 'src/config';
import VehicleIcon from 'src/components/VehicleIcon';

import useAuth from 'src/hooks/useAuth';
import useData from 'src/hooks/useData';
import axios from 'src/utils/axios';
import { fCurrencyEuro } from 'src/utils/formatNumber';
import Label from 'src/components/Label';
import LightboxModal from 'src/components/LightboxModal';
import Iconify from 'src/components/Iconify';

// @mui
import 'src/theme/DataTable.css';

dayjs.locale('de');

// ----------------------------------------------------------------------
const labelTranslations = {
  new: 'Eingang',
  submitted: 'Eingereicht',
  validated: 'Validiert',
  rejected: 'Abgelehnt',
  trade: 'Im Handel',
  paidOut: 'Ausgezahlt',
  readyForPayout: 'Bereit zur Auszahlung',
  archive: 'Archiv',
  sold: 'Vermarktet',
  unverified: 'Nicht bestätigt',
  completed: 'Abgeschlossen',
  notReady: 'Nicht bereit',
};
const labelColors = {
  new: 'primary',
  submitted: 'primary',
  validated: 'secondary',
  trade: 'info',
  rejected: 'error',
  paidOut: 'success',
  readyForPayout: 'info',
  archive: 'warning',
  notReady: 'warning',
  sold: 'success',
  unverified: 'warning',
  completed: 'success',
};

export default function DataTableSearch({ searchString, handleClose, isTyping }) {
  const theme = useTheme();
  const { user } = useAuth();
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const endpoint = '/v1/thg/search';
    axios
      .get(endpoint, {
        params: {
          limit: 5,
          searchString: searchString,
        },
      })
      .then((response) => {
        setResults(response.data.data);
      });
  }, [searchString]);

  const [openLightbox, setOpenLightbox] = useState(false);
  const registrationImages = [];
  const [selectedImage, setSelectedImage] = useState(0);

  const isLight = theme.palette.mode === 'light';

  const handleGetDocumentFile = async (filename) => {
    const documentUrl = HOST_API + '/v1/document/' + filename;
    const documentFileContent = await axios.get(documentUrl, { responseType: 'blob' });
    download(documentFileContent.data, filename);
  };

  const columns = [
    { field: 'processId', headerName: 'Vorgangsnummer', minWidth: 150 },

    {
      field: 'vehicleClass',
      headerName: 'Fahrzeugklasse',
      minWidth: 125,
      renderCell: (params) => (
        <div>
          {params.row.vehicle && (
            <div>
              <VehicleIcon vehicleClass={params.row.vehicleClass} />
              {params.row.vehicleClass}
            </div>
          )}
        </div>
      ),
    },
    {
      field: 'partnerId',
      headerName: 'Partner',
      minWidth: 175,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <div className="insurer-icon">
            <img alt={params.insurer} src={`${HOST_API}/static/logo/${params.value.toLowerCase()}_logo_icon.svg`} />
          </div>
          <Typography variant="subtitle2">{params.value}</Typography>
        </Stack>
      ),
    },
    {
      field: 'date',
      headerName: 'Eingegangen am',
      minWidth: 150,
      renderCell: (params) => <div>{dayjs(params.row.timestamp_createdAt).format('DD.MM.YYYY HH:mm')}</div>,
    },
    {
      field: 'firstName',
      headerName: 'Vorname',
      minWidth: 150,
      renderCell: (params) => <div className="rowitem">{params.row.client.firstName}</div>,
    },
    {
      field: 'lastName',
      headerName: 'Nachname',
      minWidth: 150,
      renderCell: (params) => <div className="rowitem">{params.row.client.lastName}</div>,
    },
    {
      field: 'type',
      headerName: 'Typ',
      width: 10,
      renderCell: (params) => (
        <div>
          {params.row.type && params.row.type === 'b2c' && (
            <Tooltip title="Privat">
              <Box>B2C</Box>
            </Tooltip>
          )}
          {params.row.type && params.row.type === 'b2b' && (
            <Tooltip title="Gewerbe">
              <Box>B2B</Box>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 180,
      renderCell: (params) => <div className="rowitem">{params.row.client.email}</div>,
    },
    // {
    //   field: 'registrationImages',
    //   headerName: 'Dateien',
    //   renderCell: (params) => (
    //     <Box
    //       className="files"
    //       sx={{
    //         'a:visited': {
    //           textDecoration: 'none',
    //           color: '#212B36',
    //         },
    //         'a:hover': {
    //           textDecoration: 'none',
    //           color: '#212B36',
    //         },
    //         'a:fpxus': {
    //           textDecoration: 'none',
    //           color: '#212B36',
    //         },
    //         'a:active': {
    //           textDecoration: 'none',
    //           color: '#212B36',
    //         },
    //       }}
    //     >
    //       {params.row.vehicle.registrationImage.filename
    //         .substr(
    //           params.row.vehicle.registrationImage.filename.lastIndexOf('.') + 1,
    //           params.row.vehicle.registrationImage.filename.length
    //         )
    //         .toLowerCase() === 'pdf' ? (
    //         <a
    //           className="file-link"
    //           style={{
    //             color: '#212B36',
    //             textDecoration: 'none',
    //           }}
    //           target="_blank"
    //           rel="noreferrer"
    //           href={HOST_API + '/v1/image/' + params.row.vehicle.registrationImage.filename}
    //         >
    //           <Iconify icon={'ant-design:file-twotone'} width={15} height={15} />
    //           Fahrzeugschein Vorderseite
    //         </a>
    //       ) : (
    //         <span
    //           className="file-link"
    //           onClick={() => handleOpenLightbox(params.row.vehicle.registrationImage, 'front')}
    //         >
    //           <Iconify icon={'ant-design:file-twotone'} width={15} height={15} />
    //           Fahrzeugschein Vorderseite
    //         </span>
    //       )}
    //     </Box>
    //   ),
    //   minWidth: 200,
    // },

    {
      field: 'payoutPrice',
      headerName: 'Verkaufspreis',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>
          {`${fCurrencyEuro(params.row.payoutPrice)}`}{' '}
          {params.row.instantPayout && (
            <Label sx={{ marginLeft: '10px' }} variant={isLight ? 'ghost' : 'filled'} color={'success'}>
              SOFORT
            </Label>
          )}
        </div>
      ),
    },
    // {
    //   field: 'traderReceiptNumber',
    //   headerName: 'Belegnummer',
    //   minWidth: 60,
    //   renderCell: (params) => <>{params.row.traderReceiptNumber}</>,
    // },
    // {
    //   field: 'payoutId',
    //   headerName: 'Buchungsreferenz',
    //   minWidth: 60,
    //   renderCell: (params) => <>{params.row.payoutId}</>,
    // },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Label variant={isLight ? 'ghost' : 'filled'} color={labelColors[params.value]}>
          {' '}
          {labelTranslations[params.value]}
        </Label>
      ),
    },
    {
      field: 'status_icons',
      headerName: '',
      align: 'right',
      width: 30,
      sortable: false,
      renderCell: (params) => (
        <div className="rowitem">
          <Box sx={{ display: 'flex', mt: 1 }}>
            {params.row.comments && params.row.comments.length > 0 && (
              <Tooltip title="Kommentar vorhanden">
                <Box>
                  <Iconify
                    icon={'mdi:comment-text-outline'}
                    width={20}
                    height={20}
                    color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
                    style={{}}
                  />
                </Box>
              </Tooltip>
            )}
            {params.row.charityAmount && params.row.charityAmount > 0 ? (
              <Tooltip title="Spende">
                <Box>
                  <Iconify
                    icon={'mdi:charity-outline'}
                    width={20}
                    height={20}
                    color={isLight ? 'rgba(0,0,0,0.55)' : 'rgba(255,255,255,0.55)'}
                    style={{}}
                  />
                </Box>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => {
        const actions = [];

        for (const documentItem of params.row.documents) {
          if (
            user.role === 'admin' ||
            user.role === 'supervisor' ||
            documentItem.type === 'payout-client' ||
            documentItem.type === 'client'
          ) {
            actions.push(
              <Tooltip title={documentItem.filename}>
                <GridActionsCellItem
                  onClick={() => handleGetDocumentFile(documentItem.filename)}
                  icon={<PictureAsPdfIcon />}
                  label={documentItem.filename}
                />
              </Tooltip>
            );
          }
        }
        actions.push(
          <Tooltip title="Details">
            <a href={`/app/process/detail/${params.id}`} target="_blank" rel="noopener noreferrer">
              <GridActionsCellItem icon={<VisibilityIcon />} label="Details" />
            </a>
          </Tooltip>
        );
        return actions;
      },
    },
  ];

  return (
    <>
      <Box>
        <DataGridPro
          loading={false}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={results}
          hideFooterPagination
          hideFooter
          columnHeaderHeight={0}
          autoHeight
        />
        {/* {thgsSearch.length > 0 && (
          <Box sx={{ ml: '12px' }}>
            <Link
              onClick={() => handleClose()}
              component={RouterLink}
              variant="subtitle2"
              to={`/app/process/search/${searchString}`}
            >
              Alle Ergebnisse anzeigen
            </Link>
          </Box>
        )} */}

        <LightboxModal
          images={registrationImages}
          mainSrc={registrationImages[selectedImage]}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={openLightbox}
          onCloseRequest={() => setOpenLightbox(false)}
        />
      </Box>
    </>
  );
}
