// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { HOST_API } from 'src/config';

// ----

export default function ThgqpFooter({ isPlatform, noPadding }) {
  const theme = useTheme();
  const linkTarget = isPlatform ? '_blank' : '_self';
  return (
    <Box
      className="footer"
      sx={{
        zIndex: 1500,
        marginTop: 'auto',
        borderTop: '1px solid #eee',
        display: 'flex',
        bottom: '0px',
        flexDirection: 'row',
        WebkitBoxAlign: 'center',
        alignItems: 'center',
        minHeight: { xs: 'inherit', md: '70px' },
        width: '100%',
        margin: 'auto',
        background: theme.palette.background.paper,
        paddingLeft: noPadding ? '0px' : { xs: '20px', md: '40px' },
        paddingRight: noPadding ? '0px' : { xs: '20px', md: '40px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'center', md: 'start' },
          height: { xs: '90px', md: '40px' },
        }}
      >
        <img style={{ height: '100%' }} alt="Logo" src={`${HOST_API}/static/logo/payperdrive_logo.svg`} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          WebkitBoxPack: 'end',
          justifyContent: 'flex-end',
          flexDirection: { xs: 'column', md: 'row' },
          textAlign: { xs: 'right', md: 'left' },
        }}
      >
        <Link
          target={linkTarget}
          component={RouterLink}
          rel="noreferrer"
          to="/thgqp/faq"
          sx={{
            color: '#6f6f6f',
            textDecoration: 'none',
            cursor: 'pointer',
            display: { xs: 'flex', md: 'inline-block' },
            flexBasis: '100%',
            flexDirection: 'column',
            margin: '10px',
            flex: { xs: 1, md: 0 },
          }}
        >
          FAQ
        </Link>

        <Link
          target={linkTarget}
          component={RouterLink}
          rel="noreferrer"
          to="/thgqp/nutzungsbedingungen"
          sx={{
            color: '#6f6f6f',
            textDecoration: 'none',
            cursor: 'pointer',
            display: { xs: 'flex', md: 'inline-block' },
            flexBasis: '100%',
            flexDirection: 'column',
            margin: '10px',
            flex: { xs: 1, md: 0 },
          }}
        >
          Nutzungsbedingungen
        </Link>
        <Link
          target={linkTarget}
          component={RouterLink}
          rel="noreferrer"
          to="/thgqp/datenschutz"
          sx={{
            color: '#6f6f6f',
            textDecoration: 'none',
            cursor: 'pointer',
            display: { xs: 'flex', md: 'inline-block' },
            flexBasis: '100%',
            flexDirection: 'column',
            margin: '10px',
            flex: { xs: 1, md: 0 },
          }}
        >
          Datenschutz
        </Link>
        <Link
          target={linkTarget}
          component={RouterLink}
          rel="noreferrer"
          to="/thgqp/impressum"
          sx={{
            color: '#6f6f6f',
            textDecoration: 'none',
            cursor: 'pointer',
            display: { xs: 'flex', md: 'inline-block' },
            flexBasis: '100%',
            flexDirection: 'column',
            margin: '10px',
            flex: { xs: 1, md: 0 },
          }}
        >
          Impressum
        </Link>
      </Box>
    </Box>
  );
}
