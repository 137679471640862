// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Link, Box } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

// components
import Page from 'src/components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(14),
  },
  width: '100%',
}));

// ----------------------------------------------------------------------

export default function ThgqpAgb() {
  return (
    <Page title="AGB" sx={{ display: 'flex', flexGrow: 1, margin: 'auto', width: '100%' }}>
      <RootStyle>
        <Container
          sx={{
            '& ul': {
              paddingLeft: '50px',
            },
            mt: 2,
            mb: 10,
            position: 'relative',
          }}
        >
          {/* <FaqsCategory /> */}
          <Box sx={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
            <PrintIcon
              onClick={() => {
                if (window) {
                  window.print();
                }
                return false;
              }}
            />
          </Box>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Allgemeine Geschäftsbedingungen für den THG-Quotenhandel („AGB“){' '}
          </Typography>
          <Typography variant="h7">Stand Dezember 2023</Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            1. Leistungsspektrum, Geltungsbereich
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Picsure GmbH („<strong>Picsure</strong>“) bietet über das THG-Quotenportal mit der URL
            https://payperdrive.picsure.ai (nachfolgend „<strong>THG-Quotenportal</strong>“) einen Direkthandel von
            anrechenbaren Treibhausgasminderungsquoten von Elektrofahrzeugen („<strong>THG-Quote</strong>“) zwischen
            Quotenberechtigten und Quotenaufkäufern durch Erhalt, Verkauf und Zertifizierung von THG-Quoten („
            <strong>THG-Quotenhandel</strong>“) an. Das Konzept des THG-Quotenhandels beruht auf der gesetzlichen
            Regelung, dass Anbieter fossiler Kraftstoffe ab 01. Januar 2022 zur Erfüllung der Verpflichtung zur
            Minderung der Treibhausgasemissionen auch die eingesparten CO2-Emmissionen der Fahrzeughalter von
            Elektrofahrzeugen anrechnen können. Das THG-Quotenportal stellt dementsprechend privaten und
            unternehmerischen in Deutschland ansässigen Haltern von in Deutschland zugelassenen reinen Elektrofahrzeugen
            („<strong>Berechtigter</strong>“) die Möglichkeit zur Verfügung, ihr Recht hinsichtlich der THG-Quote gemäß
            § 37a Abs. 6 BImSchG und §§ 5 ff. 38. BImSchV online geltend zu machen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Auf dem THG-Quotenportal können Berechtigte Daten und die Zulassungsbescheinigung (Teil I) für den
            THG-Quotenhandel eingeben und hochladen. Diese Daten sowie die Zulassungsbescheinigung (Teil I) werden über
            das THG-Quotenportal zum Zwecke des THG-Quotenhandels an Picsure übermittelt.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Durch Übermittlung der eingegebenen Daten und hochgeladenen Zulassungsbescheinigung (Teil I) räumt der
            Berechtigte der Picsure direkt das Recht ein, diese Daten an Dritte zum Zwecke des THG-Quotenhandels zu
            übermitteln. Als Gegenleistung erhält der Berechtigte eine THG-Quotenprämie als Vergütung. Der
            Vergütungsanspruch des Berechtigten entsteht erst mit Erhalt des verbrieften Rechts zur Anrechnung auf die
            Treibhausgasminderungsquote durch das Umweltbundesamt. Bis dahin erwirbt der Berechtigte bei
            Sofortauszahlung zunächst einen Anspruch auf Vorschuss der THG-Quotenprämie im Sinne einer Vorauszahlung.
            Diese muss, für den Fall, dass eine Anrechnung auf die THG-Quote nicht möglich ist, beispielsweise durch
            einen negativen Bescheid des Umweltbundesamtes, zurückgezahlt werden.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die THG-Quotenprämie ist ein zwischen Picsure und dem Berechtigten fest vereinbarter Betrag für jede
            THG-Quote eines Elektrofahrzeugs, die der Berechtigte durch Picsure bzw. dessen Vertragspartner beim
            Umweltbundesamt für das jeweilige Abrechnungsjahr bescheinigen lässt. Die Höhe der THG-Quotenprämie ist von
            der jeweiligen Fahrzeugklasse oder gewählten Ladeinfrastruktur abhängig und entspricht dem zum Zeitpunkt der
            Einreichung der THG-Quote auf dem Portal dargestellten Betrag.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            1.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Diese AGB gelten für den Vertrag über die THG-Quote, die Picsure hinsichtlich der Vermarktung der Rechte an
            der THG-Quote gemäß Ziffer 3 abschließt.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            2. Voraussetzungen für den THG-Quotenhandel bei Elektrofahrzeugen
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Zulässigkeit des THG-Quotenhandels zwischen Picsure und dem Berechtigten setzt die Einhaltung der
            diesbezüglichen gesetzlichen Bestimmungen, insbesondere im BImSchG und den hierzu erlassenen Verordnungen
            voraus. Es wird vorausgesetzt, dass
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                das jeweilige vertragsgegenständliche Elektrofahrzeug ein reines Batterieelektrofahrzeug (BEV) ist
                (Hybridfahrzeuge sind ausdrücklich nicht THG-quotenberechtigt),
              </li>
              <li>
                für dieses Elektrofahrzeug in dem beantragten Abrechnungsjahr noch keine Quote beantragt wurde und
              </li>
              <li>dieses auf den Berechtigten zugelassen ist oder dieser nachweislich sonst berechtigt.</li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            2.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Mit der Überarbeitung der Novelle des BImSchG zum 29.07.2023 sind weiterhin alle freiwillig zugelassenen,
            rein Batterieelektrofahrzeuge sowie Elektrofahrzeuge der folgenden Fahrzeugklassen vom THG-Quotenhandel
            ausgeschlossen: L1e-A, L1e-B, L2e-P, L2e-U, L3e-A1, L3e (Art des Aufbaus = Feld 4 = B), L4e-A1, L6e-A,
            L6e-BP, L6e-BU, 24, 25.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            3. Vertragsabschluss („THG-Quotenvertrag“)
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure bietet dem Berechtigten die Möglichkeit an, durch Nutzung des THG-Quotenportals seine Rechte im
            Hinblick auf die Geltendmachung der THG-Quote für das Elektrofahrzeug mittels Vermarktungsauftrag an Picsure
            zu übertragen und diese zugleich zu ermächtigen, diese Daten an Dritte zum Zwecke des THG-Quotenhandels zu
            übermitteln.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            3.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Zum Vertragsschluss berechtigt sind ausschließlich natürliche Personen, die das 18. Lebensjahr vollendet und
            ihren Wohnsitz in einem Mitgliedsstaat der EU haben oder juristische Personen sowie rechtsfähige
            Personengesellschaften mit Sitz in einem Mitgliedsstaat der EU, die für das ausgewählte Abrechnungsjahr
            Halter des eingereichten Elektrofahrzeugs oder vom Halter für den THG-Quotenhandel berechtigt worden sind.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            3.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Erforderlich ist zunächst die Auswahl der Ladeinfrastruktur oder der Fahrzeugklasse sowie die Übermittlung
            einer lesbaren, vollständigen Kopie der aufgeklappten Vorderseite der Zulassungsbescheinigung (Teil I).
            Diese können auf dem durch das THG-Quotenportal zur Verfügung gestellten Datenformular per Datei- Upload
            oder Fotofunktion hochgeladen werden. Weitere Daten müssen zusätzlich zwingend für die Vertragsabwicklung
            mitgeteilt werden:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>Einreichung der THG-Quotendaten als Privatperson oder Unternehmen</li>
              <li>Anrede</li>
              <li>Vor- und Nachname der verfügungsberechtigten Person</li>
              <li>Anschrift</li>
              <li>eine Telefonnummer</li>
              <li>eine E-Mail-Adresse</li>
              <li>Kontoverbindung (Kontoinhaber, IBAN)</li>
              <li>
                falls die Datenaufnahme im THG-Quotenportal nicht für eine Privatperson, sondern für ein Unternehmen
                erfolgt: Firma, Umsatzsteuer-ID des Unternehmens.
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            3.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Nach Eingabe der persönlichen Daten wird dem Berechtigten eine Zusammenfassung der eingegebenen Daten
            angezeigt und sind vom Berechtigten auf Richtigkeit zu überprüfen. Um die Daten über das THG-Quotenportal an
            Picsure zu übermitteln, muss der Berechtigte den Bedingungen durch Anklicken der entsprechenden
            Schaltflächen zustimmen und anschließend die Datenübermittlung mit Anklicken der Schaltfläche „Daten senden“
            bestätigen.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Nach erfolgreicher Übermittlung der Daten an Picsure erhält der Nutzer eine Bestätigungs-E-Mail an die zuvor
            angegebene E-Mail-Adresse. Im Rahmen einer zeitlichen Vorgabe (üblicherweise 48 Stunden) muss der Nutzer aus
            der Bestätigungs-E-Mail heraus die E-Mail-Adresse bestätigen. Mit Bestätigung der E-Mail-Adresse wird ein
            rechtsverbindlicher Vermarktungsauftrag unter den zuvor festgelegten Bedingungen geschlossen und die
            Vermarktung gestartet.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            3.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Mit dem anschließenden Versand einer Status-E-Mail an den Berechtigten erfolgt die Annahme des
            Vermarktungsauftrages und der Abschluss eines THG-Quotenvertrages zwischen Picsure und dem Berechtigten.
            Durch den Vertragsschluss erfolgt die Übertragung sämtlicher erforderlichen Rechte im Hinblick auf die
            Geltendmachung und Vermarktung der THG-Quote für das ausgewählte Abrechnungsjahr in Bezug auf das
            vertragsgegenständliche Elektrofahrzeug an Picsure. Picsure nimmt die Bestimmung als Dritten im Sinne von §
            7 sowie § 5 Abs. 1 S. 2 der 38. BImSchV und deren Rechteeinräumung an. Der Berechtigte erhält hierfür nach
            erfolgreicher Vermarktung der übermittelten Daten die dem THG-Quotenvertrag zugrunde gelegte
            THG-Quotenprämie, unter den in Ziffer 6 genannten Voraussetzungen.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            4. Übertragung der THG-Quote
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            4.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Mit Abschluss des THG-Quotenvertrages räumt der Berechtigte Picsure das Recht ein, für das betreffende
            Fahrzeug die THG-Quote im ausgewählten Abrechnungsjahr, für das der THG-Quotenvertrag abgeschlossen wird, zu
            beantragen und überträgt hiermit das alleinige Recht an der Vermarktung der THG-Quote auf Picsure
            („Übertragung der THG-Quote“). Durch die Übertragung der THG-Quote wird Picsure berechtigt im eigenen Namen
            und auf eigene Rechnung, an Stelle des Berechtigten die THG-Quote zum Zwecke der Teilnahme am
            THG-Quotenhandel an sog. Quotenaufkäufer zu übertragen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            4.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte erklärt bei der Einreichung seiner THG-Quote über das THG-Quotenportal, dass er im
            ausgewählten Abrechnungsjahr, für das der THG-Quotenvertrag abgeschlossen wird, über das von der Übertragung
            erfasste Recht uneingeschränkt verfügungsberechtigt ist und dieses nicht anderweitig übertragen hat,
            verpfändet oder gepfändet ist, oder auf sonstige Weise über dieses Recht verfügt worden ist.
          </Typography>
          <Typography variant="h4" sx={{ mt: 5 }}>
            5. Antrag auf Bescheinigung beim Umweltbundesamt
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Mit dem THG-Quotenvertrag stimmt der Berechtigte ausdrücklich zu, dass Picsure die Zulassungsbescheinigung
            (Teil I) sowie die notwendigen Daten des Berechtigten an den Quotenaufkäufer zum Zwecke der Stellung
            sämtlicher notwendiger Anträge auf Bescheinigung der THG-Quote beim Umweltbundesamt übermittelt.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            6. Auszahlung
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            6.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Auszahlung der THG-Quotenprämie an den Berechtigten erfolgt als Vorauszahlung vorbehaltlich einer
            positiven Zertifizierung durch das Umweltbundesamt.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Anspruch des Berechtigten auf die vereinbarte THG-Quotenprämie entsteht für die übertragene THG-Quote
            mit der positiven Bescheinigung des Umweltbundesamts gegenüber dem Quotenaufkäufer. Bei einem
            Sofortauszahlungsangebot zahlt Picsure unter der Bedingung der erfolgreichen Validierung der Daten des
            Berechtigten im THG-Quotenportal die vereinbarte THG-Quotenprämie vorschussweise und unter Vorbehalt bereits
            innerhalb von durchschnittlich sieben Banktagen an den Berechtigten aus. Der Berechtigte kann den Status,
            insbesondere den Status des Antragsverfahrens auf Bescheinigung des THG-Zertifikats durch das
            Umweltbundesamt, aus der Status-E-Mail über seine Vorgangsnummer abrufen. Der Anspruch auf die
            THG-Quotenprämie entsteht insbesondere dann nicht, wenn das Umweltbundesamt die Anrechnung der THG-Quote
            ablehnt, beispielsweise aus dem Grund, dass das dem THG-Quotenvertrag zugrunde gelegte Elektrofahrzeug des
            Berechtigten in dem Abrechnungsjahr beim Umweltbundesamt bereits registriert und abgerechnet wurde.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            6.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Höhe der THG-Quotenprämie ist abhängig von der jeweiligen Fahrzeugklasse oder Ladeinfrastruktur und wird
            dem Berechtigten während des Eingabevorgangs angezeigt. Die angegebene THG-Quotenprämie gilt je einmalig für
            das jeweils angemeldete Elektrofahrzeug und für das ausgewählte Abrechnungsjahr. Die THG-Quotenprämie ist
            dem Berechtigten unter den vorgenannten Bedingungen, insbesondere denjenigen gem. Ziffern 1.3. und 1.4.,
            3.2. bis 3.4., 4.1. und 4.2. sowie 5., 6.1. bis 6.4. und auch den nachstehenden Regelungen gem. Ziffer 7.,
            8. und 9. fest zugesagt und im Übrigen unabhängig von dem tatsächlich erzielten Erlös durch die Vermarktung
            der THG-Quote.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            6.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Auszahlung der THG-Quotenprämie erfolgt auf das vom Berechtigten im THG-Quotenportal hinterlegte
            Bankkonto. Picsure übernimmt keine Verantwortung für die Richtigkeit der hinterlegten Kontodaten und ist
            entsprechend nicht haftbar, falls diese Daten fehlerhaft sind und Picsure die Fehlerhaftigkeit nicht zu
            vertreten hat.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            6.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Abrechnung der THG-Quotenprämie gegenüber dem Berechtigten erfolgt im Wege einer Gutschrift inkl.
            Zahlungsbeleg (für den Fall eines Unternehmens beinhaltet die Gutschrift eine ausgewiesene Umsatzsteuer).
            Für die steuerliche Behandlung der Auszahlungsbeträge ist der Berechtigte selbst verantwortlich.
          </Typography>
          <Typography variant="h4" sx={{ mt: 5 }}>
            7. Pflichten der Vertragsparteien; Rücktritt
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte darf die THG-Quote eines angemeldeten Elektrofahrzeugs im Abrechnungsjahr, für das der
            THG-Quotenvertrag abgeschlossen wird, nicht an einen Dritten verkaufen oder übertragen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte darf für das gewählte Abrechnungsjahr weder selbst noch über Dritte einen Antrag auf
            Bescheinigung der THG-Quote beim Umweltbundesamt noch andere Anmeldungen und Anträge bei sonstigen Behörden
            gestellt haben, soweit diese für den THG-Quotenhandel erforderlich sind.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte hat insbesondere bei Gebrauchtwagen vor Abschluss des THG-Quotenvertrages sicherzustellen,
            dass der Vorbesitzer des angemeldeten Elektrofahrzeugs für das gewählte Abrechnungsjahr keine Einreichung
            der THG-Quote oder Abtretung der THG-Quote an Dritte vorgenommen hat.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Sollte ein Berechtigter vorsätzlich oder grob fahrlässig falsche Angaben gegenüber Picsure machen, die für
            die Abwicklung des THG-Quotenvertrages relevant sind, oder seine Pflichten aus Ziffern 7.1 und 7.2 verletzen
            und entstehen Picsure hierdurch Schäden, so ist der Berechtigte zum Schadensersatz verpflichtet.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure ist zum Rücktritt vom THG-Quotenvertrag berechtigt, wenn:
            <ul>
              <li>der Berechtigte falsche, unvollständige oder widersprüchliche Angaben gemacht hat,</li>
              <li>
                er entgegen seinen Angaben nicht über das Recht zur Geltendmachung und Vermarktung der THG-Quote
                verfügungsberechtigt ist oder es sich nicht um ein quotenberechtigtes Elektrofahrzeug handelt,
              </li>
              <li>
                der Berechtigte entgegen seiner Zusicherung, für das ausgewählte Abrechnungsjahr und für das
                vertragsgegenständliche Elektrofahrzeug bereits eine andere Person als Dritten bestimmt hat, an seiner
                Stelle am THG-Quotenhandel teilzunehmen,
              </li>
              <li>
                eine Ablehnung der THG-Quotenbescheinigung durch das Umweltbundesamt erfolgt oder zu erwarten ist,
              </li>
              <li>
                wenn bekannt ist oder wird, dass die Einreichung der THG-Quote mit betrügerischer Absicht erfolgt,
              </li>
              <li>
                wenn zum aktuellen Zeitpunkt oder in der Vergangenheit gegen Inhalte dieser Allgemeinen
                Geschäftsbedingungen verstoßen wurde,
              </li>
              <li>wenn die Vertragspartner im gegenseitigem Wettbewerb zueinander stehen,</li>
              <li>die Lesbarkeit und/oder die Qualität der Kopie des Fahrzeugscheins unzureichend ist.</li>
            </ul>
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Im Falle des Rücktritts erhält der Berechtigte eine E-Mail mit Angabe des jeweiligen Rücktrittgrunds.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Für den Fall des Rücktritts besteht kein Anspruch auf Auszahlung der THG-Quotenprämie.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7.6
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte ist seinerseits zum Rücktritt vom THG-Quotenvertrag berechtigt, wenn Picsure eine
            wesentliche Pflicht aus dem THG-Quotenvertrag vorsätzlich oder fahrlässig verletzt. Ist es dem Berechtigten
            zumutbar, Picsure eine angemessene Frist zur Leistung oder Nacherfüllung zu setzen, so ist der Berechtigte
            erst nach deren erfolglosem Verstreichen zum Rücktritt berechtigt.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            8. Rückforderung
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            8.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Im Falle einer Ablehnung der THG-Quote durch das Umweltbundesamt ist Picsure berechtigt, getätigte
            Vorauszahlungen der THG-Quotenprämie für das betroffene Elektrofahrzeug vollumfänglich und ohne Abzug
            zurückzufordern.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            8.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte erhält unmittelbar nach der Ablehnung der THG-Quote durch das Umweltbundesamt eine E-Mail
            mit Aufforderung zur Rückzahlung der erhaltenen Vorauszahlung.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            8.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte kann unter folgenden Kontaktdaten die Gründe zur Ablehnung durch das Umweltbundesamt
            erfahren:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Umweltbundesamt
            <br /> Fachgebiet I 2.7 - <br />
            Kraftstoffe und Energie im Verkehr <br />
            38bimschv@uba.de{' '}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Hierzu muss sich der Berechtigte mittels Kopie der Zulassungsbescheinigung (Teil I) als Halter
            identifizieren und gemäß DSGVO ein berechtigtes Auskunftsinteresse gegenüber der Behörde anmelden.{' '}
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            8.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Unabhängig der Auskunft durch das Umweltbundesamt ist der Berechtigte verpflichtet, innerhalb der in der
            E-Mail genannten Fristen die Rückzahlung der erhaltenen THG-Quotenprämie vorzunehmen. Ist die Rückzahlung
            nicht innerhalb der gesetzten Fristen erfolgt, ist Picsure berechtigt, weitere rechtliche Schritte
            einzuleiten und Schadenersatz zu fordern.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            9. Spenden
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            9.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Wird die Spendenoption bei der Einreichung der THG-Quote angezeigt, hat der Berechtigte die Möglichkeit, die
            THG-Quotenprämie ganz oder teilweise zu spenden. Hierzu wählt der Berechtigte im Formular den Spendenpartner
            und der Höhe der Spende aus.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            9.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der Berechtigte verzichtet bei der Auswahl einer Spende auf die Auszahlung der THG-Quotenprämie in Höhe der
            Spende.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            9.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Auszahlung der Spende an den ausgewählten Spendenpartner erfolgt erst nach einer positiven Bescheinigung
            der THG-Quote durch das Umweltbundesamt. Der Berechtigte wird über die Auszahlung der Spende per E-Mail
            informiert.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            9.4
          </Typography>
          <Typography sx={{ mt: 1 }}>Picsure stellt keine Spendenbescheinigung oder Spendenquittung aus.</Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            10. Widerrufsrecht
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Wenn Sie Verbraucher im Sinne des § 13 BGB sind, das heißt eine natürliche Person, die ein Rechtsgeschäft zu
            Zwecken abschließt, die überwiegend weder Ihrer gewerblichen noch Ihrer selbständigen beruflichen Tätigkeit
            zugerechnet werden können, steht Ihnen ein gesetzliches Widerrufsrecht zu, über das wir Sie wie folgt
            belehren:
          </Typography>
          <Box sx={{ border: '1px solid #000', padding: '10px', mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {' '}
              <Typography variant="h6">Widerrufsbelehrung</Typography>
            </Box>
            <Typography variant="h6" sx={{ mt: 5 }}>
              Widerrufsrecht
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
              Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben,
              müssen Sie uns,
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Picsure GmbH c/o THG-Quotenportal, Heidenkampsweg 58, 20097 Hamburg, widerruf@thg-quotenportal.de
            </Typography>
            <Typography sx={{ mt: 1 }}>
              mittels einer eindeutigen Erklärung per E- Mail über Ihren Entschluss, diesen Vertrag zu widerrufen,
              informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
              vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die
              Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.{' '}
            </Typography>

            <Typography variant="h6" sx={{ mt: 5 }}>
              Folgen des Widerrufs
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Wenn Sie diesen Vertrag widerrufen, haben Sie alle Zahlungen, die Sie von uns erhalten haben, unverzüglich
              und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf
              dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden Sie unter Angabe der Vorgangs-
              oder Belegnummer dasselbe Zahlungsmittel, das wir bei der ursprünglichen Transaktion eingesetzt haben, es
              sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart.
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns
              einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der
              Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
              Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
            </Typography>
            <Typography variant="h6" sx={{ mt: 5 }}>
              Ende der Widerrufsbelehrung
            </Typography>
          </Box>

          <Typography variant="h5" sx={{ mt: 5 }}>
            Besondere Hinweise: Erlöschen des Widerrufsrechts
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Wir weisen ergänzend darauf hin, dass Ihr Widerrufsrecht vorzeitig erlischt, wenn wir die vertraglich
            vereinbarte Dienstleistung vor Ablauf der Widerrufsfrist vollständig erbracht haben und mit der Ausführung
            der Vermarktung der THG-Quote erst begonnen haben, nachdem Sie hierzu Ihre ausdrückliche Zustimmung gegeben
            haben. Diese Zustimmung ist erfolgt, nachdem Sie aktiv den Vermarktungsprozess gestartet haben. Gleichzeitig
            haben Sie mit dem Einverständnis zu den Nutzungsbedingungen Ihr Einverständnis gegeben, dass das
            Widerrufsrecht bei vollständiger Vertragserfüllung vorzeitig erlischt.
          </Typography>

          <Box sx={{ border: '1px solid #000', padding: '10px', mt: 2, overflow: 'hidden' }}>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Muster-Widerrufsformular
            </Typography>
            <Typography sx={{ mt: 1 }}>
              (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es
              zurück.)
            </Typography>
            <Typography sx={{ mt: 1 }}>— An</Typography>
            <Typography sx={{ mt: 1 }}>
              Picsure GmbH
              <br />
              c/o THG-Quotenportal
              <br />
              Heidenkampsweg 58
              <br />
              20097 Hamburg
              <br />
              widerruf@thg-quotenportal.de
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über die Erbringung der folgenden
              Dienstleistung:
            </Typography>
            <Typography sx={{ mt: 1 }}>THG-Quotenvertrag, abgeschlossen am _______________________________</Typography>
            <Typography sx={{ mt: 1 }}>Vorgangsnummer _______________________________</Typography>
            <Typography sx={{ mt: 1 }}>Name _________________________________________________________</Typography>
            <Typography sx={{ mt: 1 }}>
              Anschrift __________________________________________________________________
            </Typography>
            <Typography sx={{ mt: 1 }}>Datum _______________________________</Typography>
          </Box>

          <Typography variant="h4" sx={{ mt: 5 }}>
            11. Haftung
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            11.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Ansprüche des Berechtigten auf Schadensersatz sind ausgeschlossen. Dies gilt nicht für
            Schadensersatzansprüche (i) aus der schuldhaften Verletzung des Lebens, des Körpers, der Gesundheit, (ii)
            aus zwingendem Recht (z.B. § 288 Abs. 6 BGB), (iii) aus vorsätzlichen oder grob fahrlässigen
            Pflichtverletzungen von Picsure, oder (iv) aus der Verletzung wesentlicher Vertragspflichten
            (Kardinalpflichten). Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße
            Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung eine Partei regelmäßig vertraut
            und vertrauen darf. Bei der Verletzung wesentlicher Vertragspflichten haftet Picsure nur auf den
            vertragstypischen, vorhersehbaren Schaden.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            11.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die sich aus Ziffer 11.1 ergebenden Haftungsbeschränkungen gelten nicht im Falle von Arglist, bei der
            Übernahme von Garantien oder einer sonstigen verschuldensunabhängigen Haftung oder wenn Schäden nach dem
            Produkthaftungsgesetz zu ersetzen sind.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            11.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die vorstehenden Haftungsbeschränkungen gelten auch für ein Verschulden Dritter, soweit diese als
            Erfüllungsgehilfen von Picsure handeln.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            12. Änderungen der AGB
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            12.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure kann diese AGB ändern, wenn
            <ul>
              <li>Bestimmungen dieser AGB durch eine Gesetzesänderung unwirksam werden; oder</li>
              <li>
                Bestimmungen dieser AGB durch eine gerichtliche Entscheidung unwirksam geworden sind oder
                voraussichtlich unwirksam werden; oder
              </li>
              <li>
                die rechtliche oder tatsächliche Situation sich hinsichtlich der Entstehung einer THG-Quote ändert und
                der Berechtigte oder Picsure diese Veränderung bei Abschluss des Vertrages nicht vorhersehen konnte.
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            12.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Picsure darf die Vertragsbedingungen jedoch nur ändern, wenn gesetzliche Bestimmungen die Ausgewogenheit des
            Vertragsgefüges nicht wiederherstellen oder diese die entstandene Lücke nicht füllen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            12.3
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Änderungen dieser AGB werden dem Berechtigten spätestens einen (1) Monat vor dem vorgeschlagenen Zeitpunkt
            ihres Wirksamwerdens in Textform angeboten. Die Zustimmung des Berechtigten gilt als erteilt, wenn er seine
            Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen angezeigt hat
            („Zustimmungsfiktion“). Auf diese Zustimmungsfiktion wird ihn Picsure in dem Angebot besonders hinweisen.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            12.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Zustimmungsfiktion der Ziffer 10.3 gilt nicht für Änderungen der Vergütung, Hauptleistungspflichten,
            Regelungen Beendigung oder sonstigen wesentlichen Änderungen, die dem Abschluss eines neuen Vertrags
            gleichkommen würde. In diesem Fall wird Picsure die Zustimmung des Berechtigten in Textform einholen.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            13. Online-Streitbeilegung
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Die EU-Kommission stellt für Verbraucher im Internet unter folgendem Link eine Streitbeilegungsplattform zur
            Online-Streitbeilegung bereit:{' '}
            <Link
              color={'#000'}
              underline="always"
              href="https://ec.europa.eu/consumers/odr"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr
            </Link>
            . Diese Streitbeilegungsplattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten
            aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist. Picsure ist zur
            Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch
            bereit.
          </Typography>

          <Typography variant="h4" sx={{ mt: 5 }}>
            14. Anwendbares Recht, Gerichtsstand und Schlussbestimmungen
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.1
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Sämtliche Kommunikation und jeder Austausch von Informationen zwischen Picsure und dem Berechtigten findet
            über die bereitgestellten Datenformulare sowie den Status-E-Mails von Picsure statt.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.2
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Der THG-Quotenvertrag unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts
            (CISG).
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.3
          </Typography>
          <Typography sx={{ mt: 1 }}>Erfüllungsort ist der Sitz von Picsure in Hamburg.</Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.4
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Gerichtsstand für sämtliche Streitigkeiten ist, soweit der Berechtigte Kaufmann, juristische Person des
            öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist oder er keinen allgemeinen Gerichtsstand
            im Inland hat, Hamburg.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.5
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Für Berechtigte, die Verbraucher sind, ist der Gerichtsstand der gesetzliche Gerichtsstand.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.6
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Sämtliche Nebenabreden, Änderungen oder Erklärungen an oder im Zusammenhang mit dem THG-Quotenvertrag
            bedürfen der Textform. Dies gilt auch für die Aufhebung dieser Textformabrede.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.7
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen dieser AGB berührt nicht die Gültigkeit der
            übrigen Teile. Anstelle der unwirksamen oder nicht durchsetzbaren Bestimmung treten, soweit vorhanden, die
            gesetzlichen Vorschriften.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            14.8
          </Typography>
          <Typography sx={{ mt: 1 }}>Vertragssprache ist Deutsch.</Typography>
        </Container>
      </RootStyle>
    </Page>
  );
}
