import PropTypes from 'prop-types';
import { createContext, useEffect, useState, useReducer } from 'react';
// hooks
import useData from 'src/hooks/useData';
import useLocalStorage from 'src/hooks/useLocalStorage';
// utils
import { defaultPreset, shadeColor } from '../utils/getColorPresets';
// config
import { defaultSettings, MAIN_THEME } from '../config';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const DEFAULT_PARTNER = 'PayPerDrive';

const DEFAULT_COLOR = '#44d9e6';

const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onResetSetting: () => {},
  colorOption: [],
  themeColors: defaultPreset,
  themePartner: DEFAULT_PARTNER,
};

const handlers = {
  CHANGE_THEME_COLOR: (state, action) => {
    const { colors } = action.payload;

    return {
      ...state,
      themeColors: colors,
    };
  },

  CHANGE_THEME_PARTNER: (state, action) => {
    const { themePartner } = action.payload;
    return {
      ...state,
      themePartner,
    };
  },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);
const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const { getUserPartner } = useData();

  useEffect(() => {
    getUserPartner();
  }, []);

  const [partnerId, setPartnerId] = useState(user && user.partnerId ? user.partnerId : DEFAULT_PARTNER);
  const [settings, setSettings] = useState({
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColorPresets: partnerId,
    // themeColors: initialState.themeColors,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
    // themePartner: initialState.themePartner,
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onToggleMode = (checked) => {
    setSettings({
      ...settings,
      themeMode: checked ? 'dark' : 'light',
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event, colorCode) => {
    const partnerId = event ? (event.target && event.target.value ? event.target.value : event) : null;
    if (partnerId) {
      setPartnerId(partnerId);
      changeThemePartner(partnerId);
    } else {
      setPartnerId(DEFAULT_PARTNER);
      changeThemePartner(DEFAULT_PARTNER);
    }

    if (colorCode && colorCode !== '#null') {
      changeThemeColor(colorCode);
    } else {
      changeThemeColor(null);
    }

    setSettings({
      ...settings,
      themeColorPresets: partnerId,
    });
  };
  const changeThemeColor = (colorCodeParam) => {
    const colorCode = colorCodeParam || DEFAULT_COLOR;
    const colors = {
      lighter: shadeColor(colorCode, 40),
      light: shadeColor(colorCode, 20),
      main: colorCode,
      dark: shadeColor(colorCode, -20),
      darker: shadeColor(colorCode, -40),
      contrastText: '#fff',
    };
    try {
      dispatch({
        type: 'CHANGE_THEME_COLOR',
        payload: {
          colors: colors,
        },
      });

      setSettings({
        ...settings,
        themeColorPresets: colors,
        themeColors: colors,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const changeThemePartner = (partnerName) => {
    const themePartner = partnerName || DEFAULT_PARTNER;
    try {
      dispatch({
        type: 'CHANGE_THEME_PARTNER',
        payload: {
          themePartner,
        },
      });

      return;
    } catch (err) {
      console.error(err);
    }

    setSettings({
      ...settings,
      themePartner: partnerName,
    });
  };

  const onChangeLayout = (event) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColors: initialState.themeColors,
      themeColorPresets: initialState.themeColorPresets,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        changeThemeColor,
        changeThemePartner,

        // Stretch
        onToggleStretch,
        // Navbar Horizontal
        onChangeLayout,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
