// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Link } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
// components
import Page from 'src/components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ThgqpDatenschutz() {
  return (
    <Page title="Datenschutzinformationen" sx={{ display: 'flex', flexGrow: 1, margin: 'auto' }}>
      <RootStyle>
        {/* <FaqsHero /> */}

        <Container
          sx={{
            '& ul': {
              paddingLeft: '50px',
            },
            mt: 15,
            mb: 10,
            position: 'relative',
          }}
        >
          {/* <FaqsCategory /> */}
          <Box sx={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
            <PrintIcon
              onClick={() => {
                if (window) {
                  window.print();
                }
                return false;
              }}
            />
          </Box>
          <Typography variant="h3" sx={{ mt: 5 }}>
            Datenschutzbestimmungen
          </Typography>
          <Typography variant="h7">Stand Januar 2023</Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            1. Information über die Erhebung personenbezogener Daten
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Mit dem Betrieb unserer Website mit der URL{' '}
            <Link
              color={'#000'}
              underline="always"
              href="https://payperdrive.picsure.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://payperdrive.picsure.ai
            </Link>{' '}
            (im Folgenden „Website“ genannt) verarbeiten wir personenbezogene Daten. Selbstverständlich behandeln wir
            Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften –
            insbesondere der Datenschutzgrundverordnung (DS-GVO) und des Bundesdatenschutzgesetzes (BDSG).
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Mit diesen Datenschutzbestimmungen informieren wir über die Erhebung personenbezogener Daten bei Nutzung
            unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name,
            Adresse, E-Mail-Adressen, Nutzerverhalten (Art. 4 Nr. 1 DS-GVO).
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            Name und Anschrift des für die Verarbeitung Verantwortlichen:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Verantwortlich gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist die Picsure GmbH,
            Heidenkampsweg 58, 20097 Hamburg.
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            Name und Anschrift der Datenschutzbeauftragten:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Unseren <strong>Datenschutzbeauftragten</strong>, Herrn Florian Bischof, erreichen Sie unter der
            E-Mail-Adresse: datenschutz@thg-quotenportal.de.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren
            Datenschutzbeauftragten wenden.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            2. Kategorien personenbezogener Daten
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Im Zusammenhang mit dem Angebot des Verkaufs Ihrer anrechenbaren Treibhausgasminderungsquoten von
            Elektrofahrzeugen und öffentlichen Ladepunkten („THG-Quote“) verarbeiten wir die folgenden personenbezogenen
            Daten:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>Persondaten (Anrede, Vorname, Nachname, Anschrift, Firma)</li>
              <li>Kontaktdaten (Telefon, E-Mail)</li>
              <li>Bankverbindung</li>
              <li>
                Informationen zu öffentlichen Ladepunkten und registrierten E-Fahrzeugen inkl. Fahrzeugschein (z.B.
                Kennzeichen, Fahrzeugidentifizierungsnummer, Fahrzeugklasse, Halterangaben, Kopie der
                Zulassungsbescheinigung Teil I)
              </li>
              <li>Status der THG-Quotenvermarktung</li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            3. Rechtsgrundlagen der Verarbeitung:
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Rechtsgrundlage für die Verarbeitung ist primär die Vertragsdurchführung gem. Art. 6 Abs. 1 lit. b) DSGVO.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten zum Schutz unserer berechtigten Interessen (z.B.
            Durchsetzung unserer Rechtsansprüche und Bedingungen, Risk Management, Verwaltung unserer
            Unternehmensanforderungen, Überwachung und Analyse, Prävention und Verhinderung von Betrug und Missbrauch
            unserer Dienste, Werbung (soweit gesetzlich zulässig), Anonymisierung zu statistischen Zwecken etc.) gem.
            Art. 6 (1) lit. f) DSGVO.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            4. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck der Verwendung
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            4.1 Datenerfassung beim Besuch unserer Website
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns
            anderweitig Informationen übermitteln, erheben wir nur die folgenden personenbezogenen Daten, die Ihr
            Browser an unseren Server gemäß §25 TTDSG übermittelt:
          </Typography>

          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>IP-Adresse des anfragenden Rechners,</li>
              <li>Datum und Uhrzeit des Zugriffs,</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT),</li>
              <li>Inhalt der Anforderung (konkrete Seite),</li>
              <li>Zugriffsstatus/HTTP-Statuscode,</li>
              <li>jeweils übertragene Datenmenge,</li>
              <li>Website, von der aus der Zugriff kommt (Referrer-URL),</li>
              <li>Browser und ggf. das sowie der Name Ihres Access-Providers,</li>
              <li>Sprache und Version der Browsersoftware.</li>
            </ul>
          </Typography>

          <Typography sx={{ mt: 1 }}>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
              <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
              <li>Auswertung der Systemsicherheit und -stabilität,</li>
              <li>Zu weiteren administrativen Zwecken</li>
            </ul>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes
            Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
            sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige
            Sitzung beendet ist.
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            4.2 Kontaktaufnahme
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes
            Formular Kontakt aufzunehmen. Bei der Nutzung des Kontaktformulars ist die Angabe Ihres Namens und einer
            gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten
            zu können. Weitere Angaben können freiwillig getätigt werden.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
            auf Grundlage Ihrer freiwillig erteilten Einwilligung. Die Daten werden ausschließlich zum Zweck der
            Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration
            gespeichert und verwendet.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Daten werden von uns gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
            erforderlich sind. Dies ist für gewöhnlich der Fall, wenn die jeweilige Kommunikation mit Ihnen beendet ist.
            Beendet ist die Kommunikation dann, wenn sich aus den Umständen entnehmen lässt, dass Ihr Anliegen
            abschließend geklärt ist. Sofern gesetzliche Aufbewahrungsfristen einer Löschung entgegenstehen, erfolgt
            eine Löschung unverzüglich nach Ablauf der gesetzlichen Aufbewahrungsfrist.
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            4.3 Datenverarbeitung bei Registrierung im THG-Quotenportal und zur Vertragsabwicklung
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Als Halter von einem oder mehreren reinen Elektrofahrzeugen oder wenn Sie vom Halter berechtigt wurden,
            können Sie die Rechte im Hinblick auf die Geltendmachung und Vermarktung der THG-Quote für das
            Elektrofahrzeug auf die Picsure GmbH übertragen und diese zugleich ermächtigen diese Daten an Dritte zum
            Zwecke der Vermarktung (Erhalt und Verkauf von Zertifikaten) zu übermitteln. Hierzu können Sie unser
            THG-Quotenportal nutzen. Erforderlich ist zunächst die Auswahl der Fahrzeugklasse sowie die Übermittlung der
            Vorderseite der Zulassungsbescheinigung (Teil 1). Diese können Sie auf unserem Portal unkompliziert
            hochladen. Folgende persönliche Daten müssen sodann für die Vertragsabwicklung mitgeteilt werden:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>Registrierung als Privatperson oder Unternehmen</li>
              <li>Vor- und Nachname</li>
              <li>Anschrift</li>
              <li>Kontoverbindung (Name des Kontoinhabers, IBAN)</li>
              <li>eine E-Mail-Adresse und Telefonnummer</li>
              <li>
                falls die Quoteneinreichung nicht für eine Privatperson, sondern für ein Unternehmen erfolgt: Firma,
                Umsatzsteuer-ID des Unternehmens.
              </li>
            </ul>
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Darüber hinaus müssen Sie folgende Erklärungen durch Anklicken der entsprechenden Optionen abgeben:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                Bestätigung, dass Sie für den ausgewählten Zeitraum der THG-Quotenberechtigte des eingereichten
                Fahrzeugscheins sind und dieser zuvor nicht eingereicht wurde. Des Weiteren, dass Sie keine andere
                Person als Dritten für die Einreichung bestimmt und berechtigt haben.
              </li>
              <li>Zustimmung zu den Nutzungsbedingungen und der Datenschutzerklärung.</li>
              <li>Zustimmung zu den Allgemeinen Geschäftsbedingungen.</li>
            </ul>
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Nach Übermittlung Ihrer Daten über das Datenformular erhalten Sie eine Bestätigungs-E-Mail, dass Ihre Daten
            erfolgreich eingegangen sind und Sie die Vermarktung nun aktiv starten müssen. Nachdem Sie die Vermarktung
            gestartet haben, erhalten Sie eine weitere E-Mail, über die Sie jederzeit den Status Ihres Antrags abrufen
            können.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Diese Verarbeitung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 S. 1 Buchstabe b) DSGVO.
          </Typography>
          <Typography variant="h6" sx={{ mt: 5 }}>
            4.4 Datenverarbeitung zum Zwecke der Anmeldung des Elektrofahrzeugs für die Treibhausgasminderungsquote
            („THG-Quote“)
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Im Rahmen des Vertragsschlusses zur Beantragung der THG-Quote für Ihr Elektrofahrzeug prüfen wir die
            Berechtigung zur Teilnahme am THG-Quotenhandel, insbesondere die jeweils gesetzlich festgelegten
            Voraussetzungen einer solchen Berechtigung in der jeweils gültigen Fassung, und übermitteln bei positivem
            Ausgang der Überprüfung den Antrag auf Bescheinigung der THG-Quote an unser Quotenkäufernetzwerk.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Wir verarbeiten Ihre personenbezogenen Daten zum Zwecke der Erfüllung des THG-Quotenvertrages.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Datenverarbeitung im Rahmen des THG-Quotenvertrages durch uns erfolgt insbesondere wie folgt:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                Ihre personenbezogenen Daten werden mittels eines SSL gesicherten Formulars erhoben und gespeichert.
              </li>
              <li>
                Ihre personenbezogenen Daten (einschließlich der Kopie der Zulassungsbescheinigung Teil I) werden dann
                von der Picsure GmbH z.B. durch die Nutzung einer Texterkennungssoftware ausgelesen und geprüft, um die
                Berechtigung zur Teilnahme am THG-Quotenhandel festzustellen.
              </li>
              <li>
                Die erforderlichen personenbezogenen Daten werden nach der Prüfung an unser Quotenkäufernetzwerk
                übermittelt, um eine Bescheinigung der THG-Quote gemäß der jeweils geltenden gesetzlichen Regelungen, zu
                erhalten.
              </li>
            </ul>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1 lit. b DSGVO
            (Erfüllung der vertraglichen Leistung aus dem THG-Quotenvertrag).
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            5. Übermittlung personenbezogener Daten an Dritte bzw. Quotenkäufer
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Um Ihre THG-Quote geltend zu machen und vermarkten zu können, ist eine Bescheinigung über die entsprechende
            Strommenge durch das Umweltbundesamt erforderlich. Die Picsure GmbH übermittelt für die Vermarktung Ihrer
            THG-Quote folgende Daten aus der/den von Ihnen hochgeladenen Zulassungsbescheinigung(en) an sog.
            Quotenaufkäufer.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>Name des Halters (Inhabers) der Zulassungsbescheinigung</li>
              <li>Datum der Zulassung</li>
              <li>Fahrzeug-Identifizierungsnummer (FIN)</li>
              <li>Antriebsart (rein Elektro)</li>
              <li>Fahrzeugklasse</li>
              <li>Datum des zwischen Ihnen und der Picsure GmbH abgeschlossenen Vertrags über Ihre THG-Quote(n)</li>
              <li>ggf. Anmerkungen zum jeweiligen Fahrzeug</li>
            </ul>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Darüber hinaus erhalten folgende Kategorien von Empfängern Zugriff auf Ihre personenbezogenen Daten:
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                Dienstleister für den Betrieb unserer Webseite und die Verarbeitung der durch die Systeme gespeicherten
                oder übermittelten Daten (z.B. für Rechenzentrumsleistungen, Zahlungsabwicklungen, IT-Sicherheit).
                Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 lit. b oder lit. f DSGVO (siehe auch Ziffer
                5), soweit es sich nicht um Auftragsverarbeiter handelt,
              </li>
              <li>
                Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich
                ist. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 lit. c DSGVO;
              </li>
              <li>
                Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (z.B. Auditoren, Banken, Versicherungen,
                Rechtsberater, Aufsichtsbehörden, Beteiligte bei Unternehmenskäufen oder der Gründung von
                Gemeinschaftsunternehmen). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 lit. b oder lit. f
                DSGVO.
              </li>
              <li>
                Dienstleister der Logistik, IT, Datenmanagements sowie weitere Auftragsverarbeiter gem. Art. 28 der
                DSGVO.
              </li>
            </ul>
          </Typography>

          <Typography variant="h5" sx={{ mt: 5 }}>
            6. Voraussetzungen der Weitergabe von personenbezogenen Daten in Drittländer
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften
            weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums
            (EWR), also in Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der
            vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu uns.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            7. Sicherheitsmaßnahmen
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Um Ihre persönlichen Daten vor fremdem Zugriff zu schützen, haben wir unsere Webseite mit einem SSL- bzw.
            TLS-Zertifikat versehen. SSL steht für „Secure-Sockets-Layer“ und TLS für „Transport Layer Security“ und
            verschlüsselt die Kommunikation von Daten zwischen einer Webseite und dem Endgerät des Nutzers. Sie erkennen
            die aktive SSL- bzw. TLS-Verschlüsselung an einem kleinen Schloss-Logo, das ganz links in der Adresszeile
            des Browsers angezeigt wird.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            8. Dauer der Speicherung personenbezogener Daten
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am
            Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
            Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen). Darüber hinaus speichern wir
            Ihre personenbezogenen Daten für die Dauer der regelmäßigen Verjährungsfrist von drei (3) Jahren.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art.
            6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung
            widerruft.
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            9. Betroffenenrechte
          </Typography>
          <Typography sx={{ mt: 1 }}>Sie haben das Recht:</Typography>
          <Typography sx={{ mt: 1 }}>
            <ul>
              <li>
                gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
                Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen
                Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die
                geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
                Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern
                diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung
                einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
              </li>
              <li>
                gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
                gespeicherten personenbezogenen Daten zu verlangen;
              </li>
              <li>
                gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen,
                soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
                Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
              </li>
              <li>
                gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,
                soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
                deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen
                die Verarbeitung eingelegt haben;
              </li>
              <li>
                gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
                anderen Verantwortlichen zu verlangen;
              </li>
              <li>
                gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies
                hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft
                nicht mehr fortführen dürfen und
              </li>
              <li>
                gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür
                an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder des Ortes des mutmaßlichen Verstoßes
                wenden.
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" sx={{ mt: 5 }}>
            10. Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit
            widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
            Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie
            Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur
            Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung
            der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe,
            weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres
            begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw.
            anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
            fortführen.
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Selbstverständlich können Sie der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
            Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter folgenden
            Kontaktdaten informieren:
          </Typography>

          <Typography sx={{ mt: 1 }}>
            Picsure GmbH
            <br />
            Heidenkampsweg 58
            <br />
            20097 Hamburg
            <br />
            E-Mail: datenschutz@thg-quotenportal.de
          </Typography>
        </Container>
      </RootStyle>
    </Page>
  );
}
