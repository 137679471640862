import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts

import GreenfactoryLayout from '../layouts/GreenfactoryLayout';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import ThgqpLayout from '../layouts/ThgqpLayout';
import DefaultLayout from '../layouts/dashboard';
// components
import LoadingScreen from 'src/components/LoadingScreen';
// guards
import AuthGuard from '../guards/AuthGuard';
import AuthGuardSupervisor from '../guards/AuthGuardSupervisor';
import AuthGuardValidator from '../guards/AuthGuardValidator';
import AuthGuardSubmissionPartner from '../guards/AuthGuardSubmissionPartner';
import GuardDirectSubmit from '../guards/GuardDirectSubmit';
import GuardFleetManagement from '../guards/GuardFleetManagement';
import GuardProcessManagement from '../guards/GuardProcessManagement';
import GuardThgInvite from '../guards/GuardThgInvite';
import GuestGuard from '../guards/GuestGuard';

import ThgqpAgb from '../pages/thgqp/agb';
import ThgqpDatenschutz from '../pages/thgqp/datenschutz';
import ThgqpFaq from '../pages/thgqp/faq';
import ThgqpImpressum from '../pages/thgqp/impressum';
import ThgqpNutzungsbedingungen from '../pages/thgqp/nutzungsbedingungen';

import GreenfactoryAgb from '../pages/greenfactory/agb';
import GreenfactoryDatenschutz from '../pages/greenfactory/datenschutz';
import GreenfactoryFaq from '../pages/greenfactory/faq';
import GreenfactoryImpressum from '../pages/greenfactory/impressum';
import GreenfactoryNutzungsbedingungen from '../pages/greenfactory/nutzungsbedingungen';

import ProcessStatus from '../pages/process/status';
import ProcessVerify from '../pages/process/verify';

import VerifySubscription from '../pages/thgqp/verifySubscription';

import { CountProvider } from '../contexts/CountContext';
import { DataProvider } from '../contexts/DataContext';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register/:registerToken',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: '/new-password/:passwordResetToken',
      element: (
        <GuestGuard>
          <NewPassword />
        </GuestGuard>
      ),
    },
    // {
    //   path: 'landingpage',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    {
      path: '/status/:thgToken',
      element: <ProcessStatus />,
    },
    {
      path: '/verify/:partnerId/:platformPartner/:verifyToken',
      element: <ProcessVerify />,
    },
    {
      path: '/verifySubscription/:verifyToken',
      element: <VerifySubscription />,
    },
    {
      path: 'thgqp',
      element: <ThgqpLayout />,
      children: [
        {
          path: 'faq',
          element: <ThgqpFaq />,
        },
        {
          path: 'nutzungsbedingungen',
          element: <ThgqpNutzungsbedingungen />,
        },
        {
          path: 'agb',
          element: <ThgqpAgb />,
        },
        {
          path: 'impressum',
          element: <ThgqpImpressum />,
        },
        {
          path: 'datenschutz',
          element: <ThgqpDatenschutz />,
        },
      ],
    },
    {
      path: 'greenfactory',
      element: <GreenfactoryLayout />,
      children: [
        {
          path: 'faq',
          element: <GreenfactoryFaq />,
        },
        {
          path: 'nutzungsbedingungen',
          element: <GreenfactoryNutzungsbedingungen />,
        },
        {
          path: 'agb',
          element: <GreenfactoryAgb />,
        },
        {
          path: 'impressum',
          element: <GreenfactoryImpressum />,
        },
        {
          path: 'datenschutz',
          element: <GreenfactoryDatenschutz />,
        },
      ],
    },
    {
      path: 'dataform',
      element: (
        <AuthGuard>
          <GuardDirectSubmit>
            <DataformPage />
          </GuardDirectSubmit>
        </AuthGuard>
      ),
    },

    {
      path: 'app',
      element: (
        <AuthGuard>
          <DataProvider>
            <CountProvider>
              <DefaultLayout />
            </CountProvider>
          </DataProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/app/dashboard'} replace />, index: true },
        {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <Dashboard />{' '}
            </AuthGuard>
          ),
        },
        {
          path: 'charity',
          element: (
            <AuthGuard>
              <Charity />
            </AuthGuard>
          ),
        },
        {
          path: 'submissions',
          element: (
            <AuthGuardSubmissionPartner>
              <Submissions />
            </AuthGuardSubmissionPartner>
          ),
        },
        {
          path: 'submissions/detail/:submissionId',
          element: (
            <AuthGuardSubmissionPartner>
              <SubmissionDetail />
            </AuthGuardSubmissionPartner>
          ),
        },
        // {
        //   path: 'thg-calculator',
        //   element: <ThgCalculator />,
        // },
        {
          path: 'payment',

          children: [
            {
              path: 'payout',
              element: (
                <AuthGuardSupervisor>
                  <PaymentPayoutPage />
                </AuthGuardSupervisor>
              ),
            },
            {
              path: 'claims',
              element: (
                <AuthGuardSupervisor>
                  <PaymentClaimsPage />
                </AuthGuardSupervisor>
              ),
            },
          ],
        },
        {
          path: 'claim',

          children: [
            {
              path: 'provisions',
              children: [
                {
                  path: 'new',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsProvisionNew />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'requested',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsProvisionRequested />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'bankReceived',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsProvisionBankReceived />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'completed',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsProvisionCompleted />
                    </AuthGuardSupervisor>
                  ),
                },
              ],
            },
            {
              path: 'clients',
              children: [
                {
                  path: 'new',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsNew />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'requested',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsRequested />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'bankReceived',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsBankReceived />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'reclaim',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsReclaim />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'reclaim-requested',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsReclaimRequested />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'reclaim-payback',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsReclaimPayback />
                    </AuthGuardSupervisor>
                  ),
                },
                {
                  path: 'completed',
                  element: (
                    <AuthGuardSupervisor>
                      <ClaimsClientsCompleted />
                    </AuthGuardSupervisor>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'process',
          children: [
            { element: <Navigate to="/app/process/new" replace />, index: true },
            {
              path: 'detail/:processId',
              element: (
                <AuthGuard>
                  <ProcessDetail />
                </AuthGuard>
              ),
            },
            {
              path: 'search/:searchStringParam',
              element: (
                <AuthGuard>
                  <ProcessSearch />
                </AuthGuard>
              ),
            },
            {
              path: 'validation/:processId',
              element: (
                <AuthGuardValidator>
                  <GuardProcessManagement>
                    <ProcessValidation />
                  </GuardProcessManagement>
                </AuthGuardValidator>
              ),
            },
            {
              path: 'new/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessNew />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'validated/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessValidated />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'submitted/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessSubmitted />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },

            {
              path: 'rejectedValidation/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessRejectedValidation />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'rejectedUba/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessRejectedUba />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },

            {
              path: 'readyForPayout/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessReadyForPayout />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'paidOut/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessPaidOut />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'completed/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessCompleted />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'archive/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessArchive />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'sold/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessSold />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },
            {
              path: 'unverified/:year',
              element: (
                <AuthGuard>
                  <GuardProcessManagement>
                    <ProcessUnverified />
                  </GuardProcessManagement>
                </AuthGuard>
              ),
            },

            {
              path: 'notReady',
              element: (
                <AuthGuard>
                  <ProcessNotReady />
                </AuthGuard>
              ),
            },
            {
              path: 'invite',
              element: (
                <AuthGuard>
                  <GuardThgInvite>
                    <ProcessInvite />
                  </GuardThgInvite>
                </AuthGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/app/" replace />, index: true },
            {
              path: 'settings',
              element: <UserSettings />,
            },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/app/" replace />, index: true },
            {
              path: 'settings/:tabItem',
              element: <UserSettings />,
            },
          ],
        },

        {
          path: 'fleet',
          children: [
            { element: <Navigate to={'/app/fleet/dashboard'} replace />, index: true },
            {
              path: 'dashboard',
              element: (
                <GuardFleetManagement>
                  <FleetDashboard />
                </GuardFleetManagement>
              ),
            },
            {
              path: 'new',
              element: (
                <GuardFleetManagement>
                  <FleetNew />
                </GuardFleetManagement>
              ),
            },
            {
              path: 'edit/:fleetId',
              element: (
                <GuardFleetManagement>
                  <FleetEdit />
                </GuardFleetManagement>
              ),
            },
            {
              path: 'vehicle/:fleetId',
              element: (
                <GuardFleetManagement>
                  <FleetVehicle />
                </GuardFleetManagement>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/Login'))));
const Register = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/Register'))));
const ResetPassword = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/ResetPassword'))));
const NewPassword = Loadable(lazy(() => lazyRetry(() => import('../pages/auth/NewPassword'))));

// Dashboard
const Dashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/dashboard'))));
const ProcessNew = Loadable(lazy(() => lazyRetry(() => import('../pages/process/new'))));
const ProcessValidated = Loadable(lazy(() => lazyRetry(() => import('../pages/process/validated'))));
const ProcessDetail = Loadable(lazy(() => lazyRetry(() => import('../pages/process/detail'))));
const ProcessValidation = Loadable(lazy(() => lazyRetry(() => import('../pages/process/validation'))));
const ProcessSubmitted = Loadable(lazy(() => lazyRetry(() => import('../pages/process/submitted'))));
const ProcessRejectedValidation = Loadable(lazy(() => lazyRetry(() => import('../pages/process/rejectedValidation'))));
const ProcessReadyForPayout = Loadable(lazy(() => lazyRetry(() => import('../pages/process/readyForPayout'))));
const ProcessRejectedUba = Loadable(lazy(() => lazyRetry(() => import('../pages/process/rejectedUba'))));

const ProcessPaidOut = Loadable(lazy(() => lazyRetry(() => import('../pages/process/paidOut'))));
const ProcessCompleted = Loadable(lazy(() => lazyRetry(() => import('../pages/process/completed'))));
const ProcessArchive = Loadable(lazy(() => lazyRetry(() => import('../pages/process/archive'))));
const ProcessSold = Loadable(lazy(() => lazyRetry(() => import('../pages/process/sold'))));
const ProcessUnverified = Loadable(lazy(() => lazyRetry(() => import('../pages/process/unverified'))));
const ProcessNotReady = Loadable(lazy(() => lazyRetry(() => import('../pages/process/notReady'))));
const ProcessInvite = Loadable(lazy(() => lazyRetry(() => import('../pages/process/invite'))));
const ProcessSearch = Loadable(lazy(() => lazyRetry(() => import('../pages/process/search'))));
const DataformPage = Loadable(lazy(() => lazyRetry(() => import('../pages/Dataform'))));
const PaymentPayoutPage = Loadable(lazy(() => lazyRetry(() => import('../pages/payment/payout'))));
const PaymentClaimsPage = Loadable(lazy(() => lazyRetry(() => import('../pages/payment/claims'))));

const ClaimsProvisionNew = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/provisions/new'))));
const ClaimsProvisionRequested = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/provisions/requested'))));
const ClaimsProvisionBankReceived = Loadable(
  lazy(() => lazyRetry(() => import('../pages/claim/provisions/bankReceived')))
);
const ClaimsProvisionCompleted = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/provisions/completed'))));

const ClaimsClientsNew = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/clients/new'))));
const ClaimsClientsRequested = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/clients/requested'))));
const ClaimsClientsBankReceived = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/clients/bankReceived'))));
const ClaimsClientsReclaim = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/clients/reclaim'))));
const ClaimsClientsReclaimRequested = Loadable(
  lazy(() => lazyRetry(() => import('../pages/claim/clients/reclaim-requested')))
);
const ClaimsClientsReclaimPayback = Loadable(
  lazy(() => lazyRetry(() => import('../pages/claim/clients/reclaim-payback')))
);
const ClaimsClientsCompleted = Loadable(lazy(() => lazyRetry(() => import('../pages/claim/clients/completed'))));

const FleetDashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/fleet/dashboard'))));
const FleetNew = Loadable(lazy(() => lazyRetry(() => import('../pages/fleet/new'))));
const FleetEdit = Loadable(lazy(() => lazyRetry(() => import('../pages/fleet/edit'))));
const FleetVehicle = Loadable(lazy(() => lazyRetry(() => import('../pages/fleet/vehicle'))));

const UserSettings = Loadable(lazy(() => lazyRetry(() => import('../pages/user/settings'))));

const Charity = Loadable(lazy(() => lazyRetry(() => import('../pages/charity'))));
const Submissions = Loadable(lazy(() => lazyRetry(() => import('../pages/submissions'))));
const SubmissionDetail = Loadable(lazy(() => lazyRetry(() => import('../pages/submissions/detail'))));

const Page500 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page500'))));
const Page403 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page403'))));
const Page404 = Loadable(lazy(() => lazyRetry(() => import('../pages/Page404'))));

const Maintenance = Loadable(lazy(() => lazyRetry(() => import('../pages/Maintenance'))));
